<template>
  <div>
    <v-card flat class="pa-0">
      <v-card-title primary-title v-if="props.user_id">
        Death Gratuity
      </v-card-title>
      <v-card-text class="px-0">
        <validation-observer ref="observer" v-slot="{ validate }">
          <v-form @submit.prevent="validate().then(checkProject)">
            <v-card flat class="pa-0">
              <v-card-title primary-title>
                <b>Score system is as follows: &nbsp;</b> [1-3 = &nbsp;
                <span class="red--text">Poor</span> , 4-6 = &nbsp;
                <span class="orange--text">Satisfactory</span>, 7-9 = &nbsp;
                <span class="yellow--text">Good</span>, 10 = &nbsp;
                <span class="green--text">Excellent</span>
                ]
              </v-card-title>

              <v-card-text>
                <v-slide-y-transition tag="v-list" group>
                  <v-row
                    v-for="(project, i) in specialProject"
                    justify="center"
                    :key="i"
                    :class="{ mycolor: project.duplicate }"
                  >
                    <v-col cols="12" lg="1" class="text-center">
                      <v-avatar size="36" color="primary mt-3">
                        <span class="white--text headline">{{ i + 1 }}</span>
                      </v-avatar>
                    </v-col>
                    <v-col>
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="{
                          required: true,
                          max: 100,
                        }"
                        :name="project.aim"
                      >
                        <v-textarea
                          class="rounded-0"
                          outlined
                          hide-details="auto"
                          label="Aim"
                          rows="1"
                          counter="100"
                          auto-grow
                          v-model="project.aim"
                          :error-messages="errors"
                        ></v-textarea>
                      </validation-provider>
                    </v-col>

                    <v-col>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="project.value_addedd"
                        :rules="{
                          required: true,
                          max: 100,
                        }"
                      >
                        <v-textarea
                          hide-details="auto"
                          outlined
                          rows="1"
                          class="rounded-0"
                          auto-grow
                          counter="100"
                          v-model="project.value_added"
                          :error-messages="errors"
                          label="Activity"
                        ></v-textarea>
                      </validation-provider>
                    </v-col>
                    <v-col>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="project.value_addedd"
                        :rules="{
                          required: true,
                          max: 100,
                        }"
                      >
                        <v-textarea
                          hide-details="auto"
                          rows="1"
                          class="rounded-0"
                          auto-grow
                          counter="100"
                          outlined
                          v-model="project.value_addedd"
                          :error-messages="errors"
                          label="Value Added"
                        ></v-textarea>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" lg="1" class="text-center">
                      <validation-provider
                        v-slot="{ errors }"
                        :vid="`name-${i}`"
                        :rules="{
                          required: true,
                          numeric: true,
                          max_value: 10,
                          min_value: 1,
                        }"
                        :name="project.score"
                      >
                        <v-text-field
                          :error-messages="errors"
                          hide-details="auto"
                          v-model="project.score"
                          outlined
                          class="rounded-0"
                          label="Score"
                        >
                        </v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12" lg="1" class="text-center">
                      <v-slide-y-reverse-transition>
                        <v-btn
                          class="mt-4"
                          icon
                          v-if="i + 1 === specialProject.length"
                          color="success"
                          @click="newRow()"
                          small
                        >
                          <v-icon> mdi-plus-circle</v-icon>
                        </v-btn>
                      </v-slide-y-reverse-transition>
                      <v-slide-x-transition>
                        <v-btn
                          v-if="specialProject.length > 1"
                          icon
                          class="mt-4"
                          small
                          color="red"
                          @click="removeRow(i)"
                        >
                          <v-icon> mdi-delete-outline </v-icon>
                        </v-btn>
                      </v-slide-x-transition>
                    </v-col>
                  </v-row>
                </v-slide-y-transition>
              </v-card-text>

              <v-card-actions>
                <v-footer color="transparent">
                  <v-btn color="success" type="submit" class="font-weight-bold">
                    Save
                  </v-btn>
                </v-footer>
              </v-card-actions>
            </v-card>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
    <Response v-if="actionResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:body1 v-if="msgBody1">{{ msgBody1 }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>

    <v-dialog
      v-model="dialog"
      max-width="590"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="primary" dark> SAVE PROJECT </v-toolbar>

        <v-card-text class="mt-6 text-h6">
          Do You Want To Save The Information?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="grey" text @click="dialog = false"> Cancel </v-btn>

          <v-btn
            color="green"
            class="font-weight-bold white--text"
            @click="saveProjectBtn"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { defineComponent, provide, reactive, toRefs, watch } from "vue";
  import Response from "@/components/ActionResponse/Response";
  import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
  import {
    required,
    regex,
    max_value,
    max,
    numeric,
    min_value,
  } from "vee-validate/dist/rules";
  import { useActions, useGetters } from "vuex-composition-helpers";

  extend("regex", {
    ...regex,
    message: "{_field_}  is not valid",
  });

  extend("max", {
    ...max,
    message: "Field should not exceed 100 characters",
  });

  extend("numeric", {
    ...numeric,
    message: "Not Valid",
  });

  extend("min_value", {
    ...min_value,
    message: "Not Valid",
  });

  extend("max_value", {
    ...max_value,
    message: "Not Valid",
  });

  extend("required", {
    ...required,
    message: "{_field_} cannot be empty",
  });

  export default defineComponent({
    components: { Response, ValidationProvider, ValidationObserver },
    setup(props) {
      const newProject = reactive({
        dialog: false,
        observer: null,
        valid: true,
        specialProject: [
          {
            aim: "",
            activity: "",
            value_added: "",
            score: null,
          },
        ],
        form: null,
        actionResponse: false,
        color: null,
        msgHeader: null,
        msgBody: null,
        msgIcon: null,
        msgBody1: null,
      });

      const { user } = useGetters(["user"]);
      const { saveProject } = useActions(["saveProject"]);

      const {
        dialog,
        specialProject,
        observer,
        actionResponse,
        color,
        msgHeader,
        msgBody,
        msgIcon,
        valid,
      } = toRefs(newProject);

      watch(
        () => [...specialProject.value],
        () => {
          actionResponse.value = false;

          const uniqueItems = [];
          specialProject.value.filter((item) => {
            if (
              uniqueItems.find(
                (i) =>
                  i.aim.toLowerCase() === item.aim.toLowerCase() &&
                  i.activity.toLowerCase() === item.activity.toLowerCase() &&
                  i.score === item.score &&
                  i.value_added.toLowerCase() === item.value_added.toLowerCase()
              )
            ) {
              item["duplicate"] = true;
              valid.value = false;
              actionResponse.value = true;
              color.value = "error";
              msgHeader.value = "Error";
              msgBody.value = "Duplicate Found";
              msgIcon.value = "mdi-close-circle";
              return true;
            }
            uniqueItems.push(item);
            delete item["duplicate"];
            valid.value = true;
            return false;
          });
        },
        { deep: true }
      );

      const newRow = async () => {
        await observer.value.validate().then((result) => {
          if (result === true) {
            specialProject.value.push({
              aim: "",
              percentage: "",
              relationship: "",
              score: null,
            });
          }
        });
      };

      provide("color", color);

      const removeRow = (i) => {
        specialProject.value.splice(i, 1);
      };

      const checkProject = async (res) => {
        res ? (dialog.value = true) : (dialog.value = false);
      };

      const saveProjectBtn = () => {
        saveProject({ user_id: user.value.id, project: specialProject.value });
      };

      return {
        ...toRefs(newProject),
        newRow,
        removeRow,
        props,
        checkProject,
        saveProjectBtn,
      };
    },
  });
</script>
<style scoped>
  .mycolor {
    border: 1px solid red;
    border-radius: 5px;
    animation: shake-animation 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
  }
  @keyframes shake-animation {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
    30%,
    100%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
</style>
