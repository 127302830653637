<template>
  <v-container fluid>
    <p class="text-uppercase grey--text">
      <span class="font-weight-light">NEW </span> <b>APPRAISAL</b>
    </p>
    <v-card>
      <v-tabs
        v-model="tab"
        active-class="primary secondary--text font-weight-bold"
        grow
      >
        <v-tab v-for="(appraisal, index) in items" :key="index">
          {{ appraisal.tab }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(appraisal, i) in items" :key="i">
          <component :is="appraisal.content"></component>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>
<script>
  import { defineComponent, reactive, toRefs } from "vue";
  import SpecialProject from "@/components/Appraisal/SpecialProject";
  import TrainingNeed from "@/components/Appraisal/TrainingNeed";
  export default defineComponent({
    components: { SpecialProject, TrainingNeed },
    setup() {
      const appraisalTemplate = reactive({
        tab: null,
        items: [
          { tab: "Special Projects", content: "SpecialProject" },
          { tab: "Training Needs", content: "TrainingNeed" },
        ],
      });
      return {
        ...toRefs(appraisalTemplate),
      };
    },
  });
</script>
